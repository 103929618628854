import { Button, ButtonAction, PillButton } from "@components/Button"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import { AltAboutJk } from "@components/AboutJk"
import { Carousel } from "@components/Carousel"
import { ClientList } from "@components/ClientList"
import { CursorMarker } from "@components/CursorMarker"
import { Layout } from "@components/Layout"
import { Arrow } from "@components/UI"
import BrandStrategyIcon from "@svg/sustainability/brand-strategy.svg"
import BrandingIcon from "@svg/sustainability/branding.svg"
import CommunicationIcon from "@svg/sustainability/communication.svg"
import Illustration from "@svg/sustainability/illustration.svg"
import React from "react"
/*global gtag*/
import { SalesForm } from "@components/Form"
import { Seo } from "@components/Seo"
import { usePageSetup } from "@components/usePageSetup"
import { splitText } from "@utils"
import { graphql } from "gatsby"
import { StaticPost } from "../components/Blog/StaticPost"

const SustainabilityPage = ({ data }) => {
  const {
    aboutJkImage,
    heroImage,
    provenProcessImage,
    csCaterpillarImage,
    csJandJImage,
    csChildrensPlaceImage,
    posts,
  } = data
  const { themeColor } = usePageSetup({
    themeColor: "bg-gray-darkest",
    navigationColor: "text-white",
    displayGeneralForm: false,
  })

  const isBrowser = typeof window !== "undefined"

  const contactSubmission = () => {
    if (!isBrowser) {
      return
    }

    gtag("event", "contact_us_form_submission", { event_category: "contact_us", event_label: "sustainability" })
  }

  const handlEbookDownload = () => {
    if (!isBrowser) {
      return
    }

    gtag("event", "sustainability_ebook_click", { event_category: "click", event_label: "sustainability_ebook" })

    window.open("/documents/jk-sustainability-ebook.pdf", "_blank").focus()
  }

  return (
    <Layout>
      <Seo title="Sustainability" description="" />
      <div className="overflow-hidden">
        <div className={themeColor} style={{ height: "94px" }}></div>
        <div className={themeColor}>
          <div className="container relative">
            <div className="absolute inset-x-0 bottom-0">
              <div className="aspect-w-326 aspect-h-93 sm:aspect-w-667 lg:aspect-w-1436 sm:aspect-h-78 extend before:bg-white-dark after:bg-white-dark"></div>
            </div>

            <div className="relative sg-hero-grid">
              <div className="mb-8 sg-title pt-14">
                <div>
                  <h1
                    className="mt-12 text-clamp-30-35"
                    data-aos="stagger"
                    dangerouslySetInnerHTML={{
                      __html: splitText({
                        "text-white":
                          "Getting your sustainability story <span>right is more important than ever.</span>",
                      }),
                    }}
                  />
                </div>
              </div>
              <div className="pb-15 sm:pb-40 sg-message" data-aos="fade-up">
                <p className="font-bold text-teal-medium text-clamp-16-18">
                  A recent PwC poll found 86% of people prefer to support or work for companies that care about the same
                  issues they do.
                  <sup>
                    <a
                      href="https://www.pwc.com/us/en/services/consulting/library/consumer-intelligence-series/consumer-and-employee-esg-expectations.html"
                      target="_blank"
                      rel="noreferrer"
                    >
                      1
                    </a>
                  </sup>{" "}
                  In other words, how you approach sustainability branding and share your sustainability story can have
                  an impact on both your talent pipeline and your bottom line.
                </p>
                <p className="text-white text-clamp-16-18">
                  JK is a leading sustainability brand agency that helps companies like yours find the sweet spot for
                  your sustainability story—defining, developing, and deploying sustainability brands and sustainability
                  marketing communications that share your progress, articulate your goals, and show your impact in a
                  compelling way.
                </p>
              </div>
              <div className="flex sg-image">
                <div className="grid self-end w-full h-full grid-cols-8 sm:grid-cols-6">
                  <div className="relative flex items-center col-span-7 sm:col-span-6 lg:col-span-5 lg:col-start-2">
                    <div className="pb-6 sm:pl-5 lg:pl-0 lg:pr-5 sm:pb-0">
                      <div className="relative" data-aos="fade-left">
                        <GatsbyImage objectFit="contain" image={getImage(heroImage)} alt="Sustainability" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <main className="mt-8">
          <section>
            <div className="container">
              <div className="flex flex-col-reverse gap-4 lg:flex-row">
                <div className="w-full pr-12 mt-8 lg:w-4/12 lg:mt-0">
                  <GatsbyImage objectFit="contain" image={getImage(provenProcessImage)} alt="Proven Process" />
                </div>
                <div className="w-full lg:w-9/12">
                  <div>
                    <h2 className="font-bold text-20">
                      Our proven process leverages best practices in sustainability branding to help you craft an ESG
                      brand identity that:
                    </h2>
                  </div>
                  <div className="grid gap-4 mt-8 lg:mt-16 lg:grid-cols-2">
                    <div>
                      <ul className="list-disc text-clamp-16-18 gap-y-3 max-w-96">
                        <li>
                          Tells an authentic and meaningful story without overstating your impact (greenwashing) or not
                          saying enough (greenhushing)
                        </li>
                        <li>Meets your customers where they are today and understands where they might be tomorrow</li>
                        <li>
                          Ensures your sustainability communications are a seamless extension of your corporate brand
                          and culture
                        </li>
                      </ul>
                    </div>
                    <div>
                      <ul className="list-disc text-clamp-16-18 gap-y-3 max-w-96">
                        <li>
                          Makes it simple to weave sustainability storytelling into your brand and talent communications
                        </li>
                        <li>
                          Helps in enhancing your brand with sustainability to build deeper connections with
                          stakeholders
                        </li>
                        <li>And so much more.</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="mt-16 lg:mt-32 pb-clamp-16-18">
            <div className="container">
              <div data-aos="fade">
                <PillButton staticButton={true} className="bg-purple-lighter">
                  How we can help
                </PillButton>
              </div>
              <div className="flex justify-center">
                <div className="flex flex-col w-full grid-cols-3 gap-8 lg:pl-6 sm:w-10/12 lg:gap-4 lg:w-full lg:grid mt-clamp-9-12">
                  <div className="flex flex-col gap-4 sm:flex-row" data-aos="fade-up">
                    <div>
                      <BrandStrategyIcon />
                    </div>
                    <div>
                      <h3 className="font-bold text-20">Sustainability brand strategy</h3>
                      <p className="mt-4 text-16">
                        Assess where you are in your sustainability journey, understand your audiences and competitive
                        landscape, and develop a strategic storytelling framework aligned with your brand and your
                        mission, vision, and values.
                      </p>
                    </div>
                  </div>
                  <div className="flex flex-col gap-4 sm:flex-row" data-aos="fade-up" data-aos-delay={100}>
                    <div>
                      <BrandingIcon />
                    </div>
                    <div>
                      <h3 className="font-bold text-20">Sustainability branding</h3>
                      <p className="mt-4 text-16">
                        Develop a distinctive narrative and visual storytelling platform that showcases your point of
                        view, your commitment to sustainability, and the progress you’ve made, and enables credible,
                        consistent messaging at every touchpoint.
                      </p>
                    </div>
                  </div>
                  <div className="flex flex-col gap-4 sm:flex-row" data-aos="fade-up" data-aos-delay={200}>
                    <div>
                      <CommunicationIcon />
                    </div>
                    <div>
                      <h3 className="font-bold text-20">Sustainability communications</h3>
                      <p className="mt-4 text-16">
                        Create a sustainability communications strategy and tactics to share your story with the
                        world—from reports, to websites, to social media, to employee comms, and more—in ways that
                        foster deeper connections and build loyalty with the audiences who matter most.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="pt-10 lg:pb-16 md:pt-clamp-14-30 lg:pt-28 bg-teal-medium">
            <div className="container">
              <div className="grid-cols-12 gap-4 lg:grid">
                <div className="flex items-center col-span-5 col-start-3" data-aos="fade-right">
                  <div>
                    <h3 className="leading-normal text-clamp-20-32 lg:pr-12">
                      Ready to tell a sustainability story that makes an impact? Want to learn how to communicate
                      sustainability efforts in a way that resonates?
                    </h3>
                    <div>
                      <Button className="mt-8 text-gray-darkest button-down" down={true} link="#sustainability-form">
                        Let’s talk!
                      </Button>
                    </div>
                  </div>
                </div>
                <div className="relative flex justify-center col-span-4 mt-10 lg:mt-0">
                  <div
                    className="relative flex justify-center w-full sm:w-10/12 lg:block lg:w-160 z-1"
                    data-aos="fade-left"
                  >
                    <Illustration />
                  </div>
                  <div className="absolute inset-x-0 bottom-0 lg:hidden">
                    <div className="aspect-w-667 aspect-h-93 extend before:bg-white-dark after:bg-white-dark"></div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="pt-clamp-10-30">
            <div className="container">
              <h3 className="text-clamp-30-45">We’re always thinking about sustainability storytelling.</h3>
              <div className="relative grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3 mt-15">
                {posts?.edges?.[0] ? (
                  <StaticPost post={posts.edges[0].node}>
                    <ButtonAction func={handlEbookDownload} className="text-white">
                      Download our ebook
                    </ButtonAction>
                  </StaticPost>
                ) : null}
                {posts?.edges?.[2] ? (
                  <StaticPost post={posts.edges[2].node}>
                    <Button
                      external={true}
                      link={`/ideas-and-insights/${posts.edges[2].node.slug}`}
                      className="text-white"
                    >
                      Learn more
                    </Button>
                  </StaticPost>
                ) : null}
                {posts?.edges?.[1] ? (
                  <StaticPost post={posts.edges[1].node}>
                    <Button
                      external={true}
                      link={`/ideas-and-insights/${posts.edges[1].node.slug}`}
                      className="text-white"
                    >
                      Learn more
                    </Button>
                  </StaticPost>
                ) : null}
              </div>
            </div>
          </section>

          <section className="pt-clamp-10-30 pb-clamp-16-30">
            <div className="container">
              <div>
                <div data-aos="fade">
                  <PillButton staticButton={true} className="bg-purple-lighter">
                    Our Clients
                  </PillButton>
                </div>
                <h2
                  className="mt-10 text-clamp-35-45"
                  data-aos="stagger"
                  dangerouslySetInnerHTML={{
                    __html: splitText({
                      "text-gray-darkest": "Goodness we’ve shared.",
                    }),
                  }}
                />
                <ClientList
                  className="gap-x-6 lg:gap-x-20 mt-clamp-9-8"
                  clients={[
                    "amgen",
                    "caterpillar",
                    "children",
                    "j-and-j",
                    "kenvue",
                    "marcos",
                    "merck",
                    "netflix",
                    "republic",
                    "santander",
                    "signify",
                    "stryker",
                    "tiffany",
                  ]}
                />
              </div>
            </div>
          </section>

          <section className="bg-gray pt-clamp-12-14 pb-clamp-15-25">
            <div className="container">
              <div>
                <PillButton className="bg-teal-medium" staticButton={true}>
                  CASE STUDY SNAPSHOTS
                </PillButton>
                <div className="mt-12">
                  <h2
                    className="leading-none text-clamp-35-45"
                    data-aos="stagger"
                    dangerouslySetInnerHTML={{
                      __html: splitText({
                        "text-white": "Some stories we’ve shared.",
                      }),
                    }}
                  />
                </div>
              </div>

              <div className="hidden grid-cols-3 gap-4 lg:grid mt-clamp-11-20">
                <div>
                  <GatsbyImage objectFit="contain" image={getImage(csCaterpillarImage)} alt="CATERPILLAR" />
                  <div className="mt-6 pr-18">
                    <h3 className="font-semi text-clamp-18-22 text-teal-light">CATERPILLAR</h3>
                    <h4 className="font-bold text-clamp-18-28 text-white-dark">
                      Constructing a strong sustainability identity.
                    </h4>
                    <p className="mt-4 text-white-dark">
                      Sustainability is a growing area of importance for Caterpillar customers around the world, so they
                      asked JK to build a communications platform that showcases how the brand is ready to support them
                      on their journeys. We also delivered a standout visual identity that telegraphs sustainability at
                      a glance.
                    </p>
                  </div>
                </div>
                <div>
                  <GatsbyImage objectFit="contain" image={getImage(csJandJImage)} alt="JOHNSON & JOHNSON" />
                  <div className="mt-6 pr-18">
                    <h3 className="font-semi text-clamp-18-22 text-teal-light">JOHNSON & JOHNSON</h3>
                    <h4 className="font-bold text-clamp-18-28 text-white-dark">
                      Sharing real progress, inside and out.
                    </h4>
                    <p className="mt-4 text-white-dark">
                      J&J has long been committed to sustainability, and they turned to JK to help them share their POV
                      and progress with internal and external audiences. We delivered dynamic websites, interactive
                      reports, and impactful employee engagement campaigns that showcase J&J’s authentic commitment to
                      environmental and social responsibility.
                    </p>
                  </div>
                </div>
                <div>
                  <GatsbyImage objectFit="contain" image={getImage(csChildrensPlaceImage)} alt="THE CHILDREN’S PLACE" />
                  <div className="mt-6 pr-18">
                    <h3 className="font-semi text-clamp-18-22 text-teal-light">THE CHILDREN’S PLACE</h3>
                    <h4 className="font-bold text-clamp-18-28 text-white-dark">
                      Reimagining a specialty retailer’s sustainability story.
                    </h4>
                    <p className="mt-4 text-white-dark">
                      The Children’s Place wanted to share their sustainability story with the world, but needed help
                      stitching it together. JK found creative ways to weave together facts and feelings, delivering an
                      award-winning report and a storytelling platform that shares their impact and their dedication to
                      making a difference every day.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="container lg:hidden mt-clamp-11-20">
              <div className="relative flex">
                <CursorMarker>
                  <CursorMarker.Gallery />
                </CursorMarker>
                <div className="w-full sm:w-5/6 lg:w-1/2">
                  <Carousel>
                    <Carousel.Swiper spaceBetween={16} centeredSlides={false}>
                      <Carousel.Slide>
                        <div data-aos="fade-up" data-aos-delay="400" className="w-full pointer-events-none">
                          <GatsbyImage image={getImage(csCaterpillarImage)} alt="CATERPILLAR" />
                          <div className="mt-6 pr-18">
                            <h3 className="font-semi text-clamp-18-22 text-teal-light">CATERPILLAR</h3>
                            <h4 className="font-bold text-clamp-18-28 text-white-dark">
                              Constructing a strong sustainability identity.
                            </h4>
                            <p className="mt-4 text-white-dark">
                              Sustainability is a growing area of importance for Caterpillar customers around the world,
                              so they asked JK to build a communications platform that showcases how the brand is ready
                              to support them on their journeys. We also delivered a standout visual identity that
                              telegraphs sustainability at a glance.
                            </p>
                          </div>
                        </div>
                      </Carousel.Slide>
                      <Carousel.Slide>
                        <div data-aos="fade-up" data-aos-delay="400" className="w-full pointer-events-none">
                          <GatsbyImage image={getImage(csJandJImage)} alt="JOHNSON & JOHNSON" />
                          <div className="mt-6 pr-18">
                            <h3 className="font-semi text-clamp-18-22 text-teal-light">JOHNSON & JOHNSON</h3>
                            <h4 className="font-bold text-clamp-18-28 text-white-dark">
                              Sharing real progress, inside and out.
                            </h4>
                            <p className="mt-4 text-white-dark">
                              J&J has long been committed to sustainability, and they turned to JK to help them share
                              their POV and progress with internal and external audiences. We delivered dynamic
                              websites, interactive reports, and impactful employee engagement campaigns that showcase
                              J&J’s authentic commitment to environmental and social responsibility.
                            </p>
                          </div>
                        </div>
                      </Carousel.Slide>
                      <Carousel.Slide>
                        <div data-aos="fade-up" data-aos-delay="400" className="w-full pointer-events-none">
                          <GatsbyImage image={getImage(csChildrensPlaceImage)} alt="THE CHILDREN’S PLACE" />
                          <div className="mt-6 pr-18">
                            <h3 className="font-semi text-clamp-18-22 text-teal-light">THE CHILDREN’S PLACE</h3>
                            <h4 className="font-bold text-clamp-18-28 text-white-dark">
                              Reimagining a specialty retailer’s sustainability story.
                            </h4>
                            <p className="mt-4 text-white-dark">
                              The Children’s Place wanted to share their sustainability story with the world, but needed
                              help stitching it together. JK found creative ways to weave together facts and feelings,
                              delivering an award-winning report and a storytelling platform that shares their impact
                              and their dedication to making a difference every day.
                            </p>
                            hero{" "}
                          </div>
                        </div>
                      </Carousel.Slide>
                    </Carousel.Swiper>
                  </Carousel>
                </div>
              </div>
            </div>
          </section>

          <Arrow colorTop="bg-transparent" colorBottom="bg-gray-darkest" fillColor="text-gray-darkest" />
          <section className="pt-clamp-44-62 pb-clamp-37-60">
            <AltAboutJk>
              <AltAboutJk.Content>
                JK blends empathy, insight, and a clear understanding of your goals to deliver communications platforms
                and programs that build the bonds that grow your business.
              </AltAboutJk.Content>
              <AltAboutJk.ImageV2 image={aboutJkImage} shape="one" />
            </AltAboutJk>
          </section>
          <Arrow colorTop="bg-gray-darkest" colorBottom="bg-gray-darkest" fillColor="text-gray-darkest" />
          <div id="sustainability-form" style={{ scrollMarginTop: "78px" }}></div>
          <section className="bg-purple-light pt-clamp-34-51 pb-clamp-27-40">
            <div className="container flex lg:justify-center">
              <div className="xl:w-10/12">
                <div className="grid-cols-10 lg:grid">
                  <div className="col-span-8">
                    <h2
                      data-aos="stagger"
                      className="text-title"
                      dangerouslySetInnerHTML={{
                        __html: splitText({
                          "text-white": "Let’s build a  stronger ",
                          break: "",
                          "text-gray-darkest": "social good story, together.",
                        }),
                      }}
                    />
                  </div>
                </div>
                <div className="grid-cols-10 lg:grid mt-clamp-7-11">
                  <div className="col-span-8 xl:col-span-7">
                    <p data-aos="fade-up">Please fill out the form below and we’ll be in touch soon.</p>
                  </div>
                </div>
              </div>
            </div>
            <section className="container mt-24">
              <div className="mx-auto xl:w-10/12">
                <SalesForm
                  id="632"
                  subject="JK Design - Sustainability"
                  recipient="katiek@jkdesign.com, markm@jkdesign.com"
                  fieldColor="bg-teal-light"
                  fn={contactSubmission}
                />
              </div>
            </section>
          </section>
        </main>
      </div>
    </Layout>
  )
}

export default SustainabilityPage

export const SustainabilityPageQuery = graphql`
  query sustainabilityPageQuery {
    heroImage: file(relativePath: { eq: "sustainability/hero.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    provenProcessImage: file(relativePath: { eq: "sustainability/proven-process.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    goingBeyondImage: file(relativePath: { eq: "social-story/going-beyond.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    csCaterpillarImage: file(relativePath: { eq: "sustainability/caterpillar.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    csJandJImage: file(relativePath: { eq: "sustainability/j-and-j.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    csChildrensPlaceImage: file(relativePath: { eq: "sustainability/childrens-place.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    aboutJkImage: file(relativePath: { eq: "social-story/about.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    posts: allWpPost(filter: { id: { in: ["cG9zdDo0OTM2", "cG9zdDo0OTcx", "cG9zdDo0MzAy"] } }) {
      edges {
        node {
          id
          title
          slug
          excerpt
          date(formatString: "MMMM DD, YYYY")
          categories {
            nodes {
              name
              slug
            }
          }
          article {
            theme
          }
          featuredImage {
            node {
              localFile {
                childImageSharp {
                  gatsbyImageData(layout: CONSTRAINED)
                }
              }
            }
          }
        }
      }
    }
  }
`
